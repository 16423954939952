.catalog-category-card {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  &_big {
    .catalog-category-card {
      &__image {
        object-fit: contain;
      }
    }
  }

  &__icon-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    display: block;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--primary-color);
  }

  &__arrow-wrap {
    width: 8px;
    height: 14px;
    display: flex;
    justify-content: center;
  }
}

@include respond-up('large') {
  .catalog-category-card {
    padding: 30px 30px 35px 40px;

    &_big {
      padding: 30px 30px 35px;

      .catalog-category-card {
        &__title {
          margin-top: 27px;
        }

        &__icon {
          margin-bottom: 11px;
        }

        &__picture {
          width: calc(200 / 1500 * 100vw);
          height: calc(190 / 1500 * 100vw);
        }
      }
    }

    &__picture {
      width: calc((var(--grid-column) + var(--grid-gap)) * 2);
      height: calc(((var(--grid-column) + var(--grid-gap)) * 2) * (196 / 232));
    }

    &__icon {
      width: 44px;
      height: 44px;
      transition: background-color .4s;
    }

    &:hover {
      .catalog-category-card {
        &__icon {
          background-color: var(--black-color);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .catalog-category-card {

  }
}

@include respond('medium') {
  .catalog-category-card {
    padding: 22px 22px 25px 25px;

    &_big {
      padding: 22px 22px 25px 28px;

      .catalog-category-card {
        &__title {
          margin-top: 33px;
        }

        &__icon {
          margin-bottom: 19px;
        }

        &__picture {
          width: calc(190 / 768 * 100vw);
          height: calc(180 / 768 * 100vw);
        }
      }
    }

    &__picture {
      width: calc((218 / 768) * 100vw);
      height: calc((184 / 768) * 100vw);
    }

    &__icon {
      width: 40px;
      height: 40px;
    }
  }
}

@include respond-down('medium') {
  .catalog-category-card {

  }
}

@include respond-down('small') {
  .catalog-category-card {
    padding: 20px 20px 22px 22px;

    &_big {
      .catalog-category-card {
        &__title {
          margin-top: 6px;
        }

        &__picture {
          width: calc(218 / 375 * 100vw);
          height: calc(184 / 375 * 100vw);
        }
      }
    }

    &__picture {
      width: calc((218 / 375) * 100vw);
      height: calc((184 / 375) * 100vw);
    }

    &__icon {
      width: 34px;
      height: 34px;
    }
  }
}