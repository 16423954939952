.footer {
  background: var(--black-color);
  &__name{
    color: var(--white-color);
    font-weight: 500;
    display: block;
  }

  &__child{
    color: var(--white-color-60);
    transition: color var(--default-transition-timing) var(--default-transition-function);
    &:hover{
      color: var(--white-color);
    }
  }

  &__link{
    padding: 4px 0;
    display: block;
  }

  &__bottom-items {
    display: flex;
  }

  &__bottom-item{
    color: var(--white-color-60);
  }

  &__rclass-link{
    color: var(--white-color);
  }
}

@include respond-up('large') {
  .footer {
    padding-top: 90px;
    padding-bottom: 50px;

    &__main{
      padding-bottom: 30px;
    }

    &__items{
      display: flex;
    }

    &__item{
      margin-right: 20px;
    }

    &__contacts{
      flex: 0 0 auto;
      //max-width: 190px;
      max-width: 205px;
    }

    &__menu-items{
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      margin-left: 116px;
    }

    &__bottom-item{
      &_company,
      &_policy {
        margin-right: 40px;
      }

      &_rclass {
        margin-left: auto;
      }
    }

    &__name{
      margin-bottom: 16px;
    }

    &__agreement,
    &__policy{
      transition: color var(--default-transition-timing) var(--default-transition-function);
      &:hover{
        color: var(--white-color);
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .footer {
    &__menu-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 50px;
      column-gap: 20px;
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__socials-mobile{
      display: none;
    }
  }
}

@include respond('medium') {
  .footer {
    padding-top: 70px;
    padding-bottom: 35px;

    &__contacts{
      grid-column: 1 / 3;
    }

    &__menu-items{
      grid-column: 3 / 7;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 50px;
      column-gap: 20px;
    }

    &__name{
      margin-bottom: 15px;
    }

    &__bottom-items{
      flex-wrap: wrap;
      row-gap: 18px;
    }

    &__bottom-item{
      &_company,
      &_policy,
      &_agreement {
        margin-right: 70px;
      }
    }
  }
}

@include respond-down('medium') {
  .footer {

  }
}
@include respond-down('small') {
  .footer {
    padding-top: 40px;
    padding-bottom: 25px;

    &__items{
      display: flex;
      flex-direction: column;
      row-gap: 0;
    }

    &__contacts{
      grid-column: 1 / 5;
      grid-row: 1 / 2;
    }

    &__menu-items{
      display: grid;
      grid-row: 2 / 3;
      grid-column: 1 / 5;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      row-gap: 40px;
      column-gap: 14px;
      margin-bottom: 40px;
    }

    &__socials-mobile{
      margin-bottom: 25px;
    }

    &__name{
      margin-bottom: 14px;
    }

    &__bottom-items {
      flex-direction: column;
    }

    &__bottom-item{
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}